import React from 'react';
import css from './fullscreenVideosLoop.module.scss';
import Video from "../Video/Video";
import useStateRef from "../../hooks/useStateRef";

const FullscreenVideosLoop = props => {
    const {videos = []} = props;

    const [currentVideoIndex, setCurrentVideoIndex, currentVideoIndexRef] = useStateRef(() => {
        return videos.length ? 0 : null;
    });

    const [wrapperHeight, setWrapperHeight, wrapperHeightRef] = useStateRef(undefined);

    if (videos.length === 0) {
        return null;
    }

    const onEnd = video => {
        setWrapperHeight(video.offsetHeight); // to prevent blinking 0px-height placeholder (without video for a moment)
        const nextVideoIndex = (currentVideoIndexRef.current === videos.length - 1) ? 0 : currentVideoIndexRef.current + 1;
        setCurrentVideoIndex(nextVideoIndex);
        setTimeout(() => {
            setWrapperHeight(undefined);
        }, 100);
    }

    return (
        <div className={css.wrapper} style={{height: wrapperHeightRef.current}}>
            {/*TODO buffer videos*/}
            {/*TODO image placeholder for the first video*/}
            {/*<div className={css.videosPlaceholder}>
                {videos.map((video, index) => {
                    return <div
                        key={`loop_video_${index}`}
                        className={css.videoPlaceholder}
                        // style={{ transform}}
                    >
                        <Video videoSrcURL={video} loop={false}/>
                    </div>
                })}
            </div>*/}
            <Video
                videoSrcURL={videos[currentVideoIndexRef.current]}
                loop={videos.length === 1}
                onEnd={onEnd}
            />
        </div>
    )
};

export default FullscreenVideosLoop;
