import React, {useState} from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import css from "./explore.module.scss";
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../components/componentsUtils";
import markingsGallery1 from "../../images/sections/markingsGallery/markings_gallery_1.jpg"
import markingsGallery2 from "../../images/sections/markingsGallery/markings_gallery_2.jpg"
import markingsGallery3 from "../../images/sections/markingsGallery/markings_gallery_3.jpg"
import markingsGallery4 from "../../images/sections/markingsGallery/markings_gallery_4.jpg"
import markingsGallery5 from "../../images/sections/markingsGallery/markings_gallery_5.jpg"
import markingsGallery6 from "../../images/sections/markingsGallery/markings_gallery_6.jpg"
import markingsGallery7 from "../../images/sections/markingsGallery/markings_gallery_7.jpg"
import markingsGallery8 from "../../images/sections/markingsGallery/markings_gallery_8.jpg"
import markingsGallery9 from "../../images/sections/markingsGallery/markings_gallery_9.jpg"
import markingsGallery10 from "../../images/sections/markingsGallery/markings_gallery_10.jpg"
import markingsGallery11 from "../../images/sections/markingsGallery/markings_gallery_11.jpg"
import markingsGallery12 from "../../images/sections/markingsGallery/markings_gallery_12.jpg"
import markingsGallery13 from "../../images/sections/markingsGallery/markings_gallery_13.jpg"
import markingsGallery14 from "../../images/sections/markingsGallery/markings_gallery_14.jpg"
import markingsGallery15 from "../../images/sections/markingsGallery/markings_gallery_15.jpg"
import AppLightbox from "../../components/shared/Lightbox/AppLightbox";
import BackToTraceabilityButton from "../../components/shared/ButtonBackTo/BackToTraceabilityButton";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import FullscreenVideosLoop from "../../components/shared/fullscreenVideosLoop/FullscreenVideosLoop";
import TimberIDMovie from "./TimberID.mp4";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_markings_gallery"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Explore = () => {

    return <RedirectTo url={getLink([getRoutes().TechnologyMobileAppsDcodeGallery])}/>;

    /*
    const routes = getRoutes();
    const pageTitle = routes.Traceability.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const [openLightbox, setOpenLightbox] = useState(false);
    const images = [
        {src: markingsGallery1, index: 0},
        {src: markingsGallery2, index: 1},
        {src: markingsGallery3, index: 2},
        {src: markingsGallery4, index: 3},
        {src: markingsGallery5, index: 4},
        {src: markingsGallery6, index: 5},
        {src: markingsGallery7, index: 6},
        {src: markingsGallery8, index: 7},
        {src: markingsGallery9, index: 8},
        {src: markingsGallery10, index: 9},
        {src: markingsGallery11, index: 10},
        {src: markingsGallery12, index: 11},
        {src: markingsGallery13, index: 12},
        {src: markingsGallery14, index: 13},
        {src: markingsGallery15, index: 14},
    ]
    const [lightboxImages, setLightboxImages] = useState([]);

    const showPreview = (index) => {
        setOpenLightbox(true);
        const next = images.filter(value => value.index > index)
        const prev = images.filter(value => value.index < index)
        setLightboxImages([images[index], ...next, ...prev])
    };

    const onLightboxClose = () => {
        setOpenLightbox(false);
    };

    const renderSection = (index, imageAlt = '', image) => {
        return (
            <div className={css.section}>
                <div className={css.sectionImageWrapper} onClick={() => showPreview(index)}>
                    {renderImageByFileName(imagesFluid, image, imageAlt)}
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={routes.ForestryExplore.pageTitle}
                parentTitles={[pageTitle]}
                parentRoutes={[routes.Traceability]}
            />

            {/!*<div className={css.wrapper} style={openLightbox ? {position: 'fixed'} : {}}>*!/}
            <div className={css.wrapper}>
                <div className={css.title}>
                    {routes.ForestryExplore.pageTitle}
                </div>
                <div className={css.text}>
                    <div>Dcode Marker Hammer manual version in action.</div>
                    <div>Dcode Marker Automated coming soon.</div>
                </div>
                <div className={css.videosWrapper}>
                    <FullscreenVideosLoop
                        videos={[
                            TimberIDMovie,
                        ]}
                    />
                </div>
                <div className={css.sectionsWrapper}>
                    {renderSection(0, "forestry explore 1", "markings_gallery_1s.png")}
                    {renderSection(1, "forestry explore 2", "markings_gallery_2s.png")}
                    {renderSection(2, "forestry explore 3", "markings_gallery_3s.png")}
                    {renderSection(3, "forestry explore 4", "markings_gallery_4s.png")}
                    {renderSection(4, "forestry explore 5", "markings_gallery_5s.png")}
                    {renderSection(5, "forestry explore 6", "markings_gallery_6s.png")}
                    {renderSection(6, "forestry explore 7", "markings_gallery_7s.png")}
                    {renderSection(7, "forestry explore 8", "markings_gallery_8s.png")}
                    {renderSection(8, "forestry explore 9", "markings_gallery_9s.png")}
                    {renderSection(9, "forestry explore 10", "markings_gallery_10s.png")}
                    {renderSection(10, "forestry explore 11", "markings_gallery_11s.png")}
                    {renderSection(11, "forestry explore 12", "markings_gallery_12s.png")}
                    {renderSection(12, "forestry explore 13", "markings_gallery_13_s.png")}
                    {renderSection(13, "forestry explore 14", "markings_gallery_14_s.png")}
                    {renderSection(14, "forestry explore 15", "markings_gallery_15_s.png")}
                </div>
                <BackToForestryButton/>

                <AdditionalInformation/>

                <AppLightbox
                    open={openLightbox}
                    close={onLightboxClose}
                    slides={lightboxImages}
                />

                <Footer/>

            </div>
        </Layout>
    );*/
};

export default Explore;
